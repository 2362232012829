/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex, H1, H2, Inner, Outer } from '../components/styled';
import { headerHeight, jumbotronHeight } from '../components/theme/measurements';
import BaseLayout from '../components/layout/BaseLayout';
import BlogPostGrid from '../components/blog/post-grid';
import Footer from '../components/footer';
import Header from '../components/header';
import blogBg from '../images/bg_blog.svg';

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...BlogPostsData
        }
      }
    }
  }
`;

const BlogPage = ({ data }) => {
  const posts = data.allMdx.edges;

  return (
    <BaseLayout seoProps={{ title: 'Sprintlio Blog' }}>
      <Header />
      <Flex
        mt={headerHeight}
        flexDirection="column"
        justifyContent="center"
        height={jumbotronHeight}
        color="white"
        sx={{
          backgroundImage: `url(${blogBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          '& > *': { textAlign: 'center' },
        }}
      >
        <H1>Sprintlio Blog</H1>
        <H2 mt={20} maxWidth={600} px={4} variant="header2">
          Learnings, inspiration, and behind-the-scenes about remote agile retrospective
          best-practices and tools, from our team to yours.
        </H2>
      </Flex>
      <Outer>
        <Inner pt={80}>
          <BlogPostGrid posts={posts} />
        </Inner>
      </Outer>
      <Footer />
    </BaseLayout>
  );
};
BlogPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default BlogPage;
